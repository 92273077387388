import { FormDataBuilder } from '@shared-models/server-route/form-data-builder/interface'

export class DomFormDataBuilder implements FormDataBuilder {
  #finalized = false
  #formData = new FormData()

  append(name: string, value: string | number | Blob): this {
    if (typeof value === 'number') {
      this.#formData.append(name, `${value}`)
    } else {
      this.#formData.append(name, value)
    }

    return this
  }

  finalize(): object {
    if (this.#finalized) {
      throw new Error('Already finalized')
    }

    this.#finalized = true
    return this.#formData
  }

  set(name: string, value: string | number | string[] | Blob | undefined | null): this {
    if (value === null || value === undefined) {
      this.#formData.delete(name)
    } else if (Array.isArray(value)) {
      for (const item of value) {
        this.#formData.append(name, item)
      }
    } else if (typeof value === 'number') {
      this.#formData.set(name, `${value}`)
    } else {
      this.#formData.set(name, value)
    }

    return this
  }
}
