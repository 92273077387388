import { Route } from '@shared-models/server-route'
import { CasePath } from '@technicated/ts-enums'
import { isEqual } from 'lodash-es'
import { map, Observable } from 'rxjs'
import { z } from 'zod'

export const unit = Symbol('ApiClient unit value')
export type Unit = typeof unit

export abstract class ApiClient {
  abstract apiRequest(route: Route): Observable<unknown>

  decodedApiRequest<Remote, Local>(route: Route, schema: z.ZodType<Local, z.ZodTypeDef, Remote>): Observable<Local> {
    return this.apiRequest(route).pipe(
      map((data) => schema.parse(data)),
    )
  }

  noContentApiRequest(route: Route): Observable<Unit> {
    return this.apiRequest(route).pipe(
      map((response) => {
        if (response !== unit) {
          console.warn('Value returned from API call when using "noContentApiRequest"', response)
        }

        return unit
      }),
    )
  }

  override(exact: Route, response: () => Observable<unknown>): void {
    const original = this.apiRequest.bind(this)

    this.apiRequest = (route: Route): Observable<unknown> => {
      if (isEqual(route, exact)) {
        return response()
      } else {
        return original(route)
      }
    }
  }

  overrideMatching<Value>(matcher: CasePath<Route, Value>, response: (value: Value) => Observable<unknown>): void {
    const original = this.apiRequest.bind(this)

    this.apiRequest = function apiRequest(route: Route): Observable<unknown> {
      const extractionResult = matcher.extract(route)

      if (extractionResult) {
        return response(extractionResult.value)
      } else {
        return original(route)
      }
    }
  }
}
