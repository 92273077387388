import { Routes } from '@angular/router'

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('../layout/split-view/split-view.component').then((mod) => mod.SplitViewComponent),
    children: [
      {
        path: 'archive',
        loadComponent: () => import('../layout/archive/archive.component').then((mod) => mod.ArchiveComponent),
      },
      {
        path: 'certifications',
        loadChildren: () => import('../certifications/routes'),
      },
      {
        path: 'dashboard',
        loadComponent: () => import('../layout/dashboard/dashboard.component').then((mod) => mod.DashboardComponent),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
]
