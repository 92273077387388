export const _42 = 42

// import { Certifications, Route, Visura } from '@shared-models'
// import { of } from 'rxjs'
// import {
//   InProgressForeclosureItem,
// } from '../certifications/in-progress/in-progress-foreclosure-list/in-progress-foreclosure-list.component'
// import { ApiClient } from './interface'
//
// declare module './interface' {
//   export interface ApiClient {
//     mockVisureInProgressList(): void
//   }
// }
//
// ApiClient.prototype.mockVisureInProgressList = function mockVisureInProgressList(this: ApiClient): void {
//   this.overrideMatching(
//     Route('certifications')
//       .appending(Certifications('visura'))
//       .appending(Visura('inProgressList')),
//     () => of(
//       [
//         {
//           id: '4d3d1e3f-ea24-4b9e-bd6a-e8d7621aa5bd',
//           title: '73658295 Capuozzo - Caputo 15/03/2024',
//           immobili: 2,
//           visure: 1,
//           elenchi: 2,
//           note: 3,
//           lastEdit: '2024-03-15T14:54',
//           user: {
//             id: 'c406a53b-e176-4a44-b227-001035e33f6d',
//             name: 'Giulia Ambrogi',
//           },
//         },
//         {
//           id: '7def2649-8002-4b4a-a284-3905a7f3c6a7',
//           title: '73658295 Mario - Luigi 24/10/2022',
//           immobili: 4,
//           visure: 2,
//           elenchi: 1,
//           note: 6,
//           lastEdit: '2023-08-18T18:41',
//           user: {
//             id: 'e4b2ef24-3f08-42cc-b90e-fee38b56fa13',
//             name: 'Mario Rossi',
//           },
//         },
//       ] satisfies InProgressForeclosureItem[]),
//   )
// }
