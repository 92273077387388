import { Route } from '@shared-models/server-route'
import { Observable } from 'rxjs'
import { ApiClient } from './interface'

function unimplemented(
  clazz: { new(...args: never[]): unknown },
  method: (...args: never[]) => unknown,
): never {
  throw new Error(`${clazz.name}.${method.name}`)
}

export class UnimplementedApiClient extends ApiClient {
  override apiRequest(route: Route): Observable<object> {
    console.log(route)
    unimplemented(UnimplementedApiClient, this.apiRequest)
  }
}
