import { FormDataBuilder } from '@shared-models/server-route/form-data-builder/interface'

export class ObjectFormDataBuilder implements FormDataBuilder {
  #finalized = false
  storage: Partial<Record<string, unknown>> = {}

  append(name: string, value: string | number | Blob): this {
    const original = this.storage[name]
    let result: unknown[]

    if (Array.isArray(original)) {
      result = [...original, value]
    } else if (original === undefined) {
      result = [value]
    } else {
      result = [original, value]
    }

    this.storage[name] = result
    return this
  }

  finalize(): object {
    if (this.#finalized) {
      throw new Error('Already finalized')
    }

    this.#finalized = true
    return this.storage
  }

  set(name: string, value: string | number | string[] | null | undefined | Blob): this {
    this.storage[name] = value
    return this
  }
}
