import '@angular/common/locales/global/it'
import { provideHttpClient } from '@angular/common/http'
import { enableProdMode, LOCALE_ID } from '@angular/core'
import { bootstrapApplication } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter } from '@angular/router'
import { ApiClient, ApiClientLive } from '@api-client'
import { provideIonicAngular } from '@ionic/angular/standalone'
import { Calendar, LiveCalendar } from '@services/calendar'
import { AppComponent } from './app/app.component'
import { routes } from './app/app.routes'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ApiClient, useClass: ApiClientLive },
    { provide: Calendar, useClass: LiveCalendar },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    provideAnimations(),
    provideHttpClient(),
    provideIonicAngular(),
    provideRouter(routes),
  ],
})
